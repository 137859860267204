<svelte:options customElement={{ tag: "bt-interests-carousel", shadow: "none", }} />

<script lang="ts">
	import PillCheckbox from '$shared/pill-checkbox/PillCheckbox.svelte';
	import ActionButton from '$shared/action-button/ActionButton.svelte'

	export let interestsBannerEmptyStateMessage = "You haven't added any interests yet.";
	export let interestsBannerEmptyAddMessage = "Add your interests and role to generate recommendations here.";
	export let interestsBannerEmptyButton = "Get started";
</script>

<section class="interests-carousel" id="recommendations">
	<div class="interests-carousel__left-cloud" aria-hidden="true" role="presentation">
		<PillCheckbox label="Lifelong Learning" visualOnly />
		<PillCheckbox label="Leadership" visualOnly />
		<PillCheckbox label="Communication" visualOnly />
		<PillCheckbox label="Health & Wellness" visualOnly />
		<PillCheckbox label="Management" visualOnly />
		<PillCheckbox label="Sales" visualOnly />
		<PillCheckbox label="Communication" visualOnly />
	</div>
	<div class="interests-carousel__right-cloud" aria-hidden="true" role="presentation">
		<PillCheckbox label="Lifelong Learning" visualOnly />
		<PillCheckbox label="Leadership" visualOnly />
		<PillCheckbox label="Health & Wellness" visualOnly />
		<PillCheckbox label="Sales" visualOnly />
		<PillCheckbox label="Management" visualOnly />
		<PillCheckbox label="Communication" visualOnly />
		<PillCheckbox label="Lifelong Learning" visualOnly />
	</div>
	<div class="interests-carousel__content">
		<span>{interestsBannerEmptyStateMessage}</span>
		<span>{interestsBannerEmptyAddMessage}</span>
		<ActionButton design="primary"><a href="/interests/edit">{interestsBannerEmptyButton}</a></ActionButton>
	</div>
</section>

<style>
	.interests-carousel {
		background: white;
		height: 16rem;
		width: 100%;
		overflow: hidden;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid theme("colors.neutral.300");
	}
	.interests-carousel__content {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		padding: 0 2rem;
		& span {
			text-align: center;
		}
		& span:nth-child(1) {
			font-weight: 600;
			font-size: theme('fontSize.2xl');
			margin-bottom: 1rem;
		}
		& span:nth-child(2) {
			font-size: theme('fontSize.lg');
			margin-bottom: 1.5rem;
		}
	}
	.interests-carousel__left-cloud,
	.interests-carousel__right-cloud {
		width: 30%;
		height: 100%;
		position: absolute;
		top: 0;
		opacity: .25;
		@media (max-width: 50rem) {
			opacity: .1;
		}
	}
	.interests-carousel__left-cloud {
		left: 0;
	}
	.interests-carousel__right-cloud {
		right: 0;
	}
	:global(.interests-carousel__left-cloud .pill-checkbox) {
		position: absolute;
		&:nth-child(1) {
			top: 1rem;
			left: -4rem;
		}
		&:nth-child(2) {
			top: 4rem;
			left: -5rem;
		}
		&:nth-child(3) {
			top: 4rem;
			left: 3.5rem;
		}
		&:nth-child(4) {
			top: 7rem;
			left: -.25rem;
		}
		&:nth-child(5) {
			top: 10rem;
			left: -5rem;
		}
		&:nth-child(6) {
			top: 10rem;
			left: 4rem;
		}
		&:nth-child(7) {
			top: 13rem;
			left: -2rem;
		}
	}
	:global(.interests-carousel__right-cloud .pill-checkbox) {
		position: absolute;
		&:nth-child(1) {
			top: 1rem;
			right: -4rem;
		}
		&:nth-child(2) {
			top: 4rem;
			right: -5rem;
		}
		&:nth-child(3) {
			top: 4rem;
			right: 3.5rem;
		}
		&:nth-child(4) {
			top: 7rem;
			right: -.75rem;
		}
		&:nth-child(5) {
			top: 10rem;
			right: -5rem;
		}
		&:nth-child(6) {
			top: 10rem;
			right: 4rem;
		}
		&:nth-child(7) {
			top: 13rem;
			right: -2rem;
		}
	}
</style>