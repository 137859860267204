<svelte:options customElement={{ tag: "bt-pdf-button", shadow: "none", }} />

<script lang="ts">
  import Icon from '$shared/icons/Icon.svelte';
  import LoadingIcon from '../icons/loading.svg.svelte';

  export let text: string = 'Download PDF';
  export let clickedText = 'Downloading...';
  export let link = '';

  export let isLoading = false;

  let buttonEl: DOMElement;
  let clickedTextEl: DOMElement;
  let defaultTextEl: DOMElement;
  let clickedIconEl: DOMElement;
  let defaultIconEl: DOMElement;

  const generatePDF = (event) => {
    event.preventDefault()
    buttonEl.classList.add("disabled")
    isLoading = true;

    generatePDFAsync(link)
      .then(({ blob, filename }) => {
        downloadPDF(blob, filename)
        buttonEl.classList.remove("disabled")
        isLoading = false;
      })
      .catch((error) => {
        window.setTimeout(() => {
          console.error("Error generating PDF:", error)
          buttonEl.classList.remove("disabled")
          isLoading = false;
        }, 1000)
      })
  }
  const generatePDFAsync = async(url) => {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/pdf',
        'X-Requested-With': 'XMLHttpRequest', // This is required for Rails
      },
      credentials: 'same-origin',
    })

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    const blob = await response.blob()
    const contentDisposition = response.headers.get('Content-Disposition')
    let filename = 'download.pdf'  // Default filename

    // Try to extract filename from Content-Disposition header
    if (contentDisposition) {
      // First, try to get the filename* parameter (RFC 5987)
      const filenameStarMatch =
        /filename\*=UTF-8''(.+)/i.exec(contentDisposition)
      if (filenameStarMatch) {
        filename = decodeURIComponent(filenameStarMatch[1])
      } else {
        // If filename* is not found, try the regular filename parameter
        const filenameMatch = /filename="?(.+)"?/i.exec(contentDisposition)
        if (filenameMatch) {
          filename = filenameMatch[1].replace(/['"]/g, '')
        }
      }
    }

    return { blob, filename }
  }

  const downloadPDF = (blob, filename) => {
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')

    link.href = url
    link.download = decodeURIComponent(filename)
    link.click()

    // Release the object URL after the download is complete
    setTimeout(() => URL.revokeObjectURL(url), 100)
  }

</script>

<div>
  <a href="{link}" 
    bind:this={buttonEl}
    on:click={generatePDF}
    class="max-w-sm p-4 mt-8 border group
    bg-neutral-900 text-white 
    hover:bg-transparent hover:text-neutral-900">
    <div>
      <span class={`font-semibold ${isLoading ? '' : 'hidden'}`} bind:this={clickedTextEl}>{clickedText}</span>
      <span class={`font-semibold ${isLoading ? 'hidden' : ''}`} bind:this={defaultTextEl}>{text}</span>
    </div>
    <span bind:this={defaultIconEl} class={`ml-4 ${isLoading ? 'hidden' : ''}`}>
      <Icon icon="PDF" size="medium"></Icon>
    </span>
    <div bind:this={clickedIconEl} class="{`${isLoading ? '' : 'hidden'}`}">
      <LoadingIcon/>
    </div>
  </a>
</div>

<style>
  a {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  a div {
    display: flex;
    flex-direction: column;
    max-width: 75%;
    text-align: left;
  }
  /* Necessary to duplicate this from theme.css so that not overriden by display: flex */
  .hidden {
    display: none;
  }
  @media print {
    a {
      display: none !important;
    }
  }
</style>
