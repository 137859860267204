<script lang="ts">
	import { createEventDispatcher } from 'svelte';
	import Icon from '$shared/icons/Icon.svelte';

  export let addToCurationTextEnter = 'Enter';
  export let addToCurationTextPlaceholder = 'New Curation';

	const dispatch = createEventDispatcher();

	let value: string = '';

	function init(el: any) {
		el.focus();
	}

	function handleSubmit(e: any) {
		e.preventDefault();
		e.stopPropagation();

		dispatch('newCurationSubmit', {
			value
		});
		value = '';
	}
</script>

<form on:submit={handleSubmit} class="relative">
	<input
		class="border w-full p-1 px-3 rounded
        focus:outline-none focus:border-black
        pr-16"
		type="text"
		bind:value
		use:init
		placeholder={addToCurationTextPlaceholder}
	/>
	<div
		class="absolute w-full h-full pointer-events-none flex justify-end items-center top-0 left-0 px-2"
	>
		<button
			type="submit"
			value={addToCurationTextEnter}
			disabled={!value.length}
			class="group border flex gap-1 {value.length > 0
				? 'border-green-400 text-green-400 hover:text-white hover:bg-green-400'
				: 'border-neutral-550 text-neutral-550'} text-xs px-2 py-px rounded pointer-events-auto transition-all"
		>
			<div class="w-3 relative">
				<Icon icon="EnterKey" iconClass={`absolute w-full h-3 left-0 top-1 ${value.length > 0 ? 'transition-all fill-green-400 group-hover:fill-white' : 'fill-neutral-500'}`} />
			</div>
			Enter
		</button>
	</div>
</form>
