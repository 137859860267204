<script lang="ts">
  import { onMount } from 'svelte';
  import { dateStore } from '$comp/analytics/dateStore';
	import DataTableActions from '$lib/components/analytics/AnalyticsDataTableActions.svelte';
  import * as Tooltip from '$lib/components/ui/tooltip';
  import { numberWithCommas, toHoursAndMinutes } from '$lib/utils';

  type Capability = {
    name: string
    viewTime: number;
    contentViews: number;
    toolTips: object;
  };
  export let topCapabilities:Capability[] = [];

  let dateRange: string = '';

  onMount(() => {
    dateStore.subscribe(() => {
      dateRange = dateStore.getDateRangeDisplayOrEmpty()
    });
  })
</script>

<div class="flex justify-between items-center mb-4">
  <p class="font-semibold text-neutral-500 text-sm">Top Capabilities</p>
  <span class="align-right text-sm text-neutral-500">{dateRange}</span>
</div>
<div
  class="relative overflow-x-auto overflow-y-auto border border-neutral-200 shadow-md sm:rounded-lg"
>
  <table class="w-full text-sm text-left text-neutral-800">
    <thead class="sticky top-0 text-sm bg-white border-b border-neutral-200">
      <tr class="select-none">
        <th scope="col" class="px-4 py-3 font-normal">
          <Tooltip.Root>
            <Tooltip.Trigger> Name </Tooltip.Trigger>
            <Tooltip.Content>
              <p>Name of the Capability</p>
            </Tooltip.Content>
          </Tooltip.Root>
        </th>
        <th scope="col" class="px-4 py-3 font-normal whitespace-nowrap">
          <Tooltip.Root>
            <Tooltip.Trigger> Video View Time </Tooltip.Trigger>
            <Tooltip.Content>
              <p>View time of this Capability</p>
            </Tooltip.Content>
          </Tooltip.Root>
        </th>
        <th scope="col" class="px-4 py-3 font-normal whitespace-nowrap text-right">
          <Tooltip.Root>
            <Tooltip.Trigger> Content Views </Tooltip.Trigger>
            <Tooltip.Content>
              <p>Number of video and course views for this Capability</p>
            </Tooltip.Content>
          </Tooltip.Root>
        </th>
        <th scope="col" class="px-4 py-3 font-normal whitespace-nowrap">
          Actions
        </th>
      </tr>
    </thead>
    <tbody>
      {#each topCapabilities as capability}
        <tr class="bg-white border-b border-neutral-200 last:border-b-0">
          <th scope="row" class="px-4 py-4 font-normal text-gray-900 whitespace-nowrap">
            <div class="h-12 flex flex-col justify-center">
              <a href="/search?q=*&primary_capabilities[]={capability.name}">{capability.name}</a>
            </div>
          </th>
          <td class="px-4 py-4">
            {toHoursAndMinutes(capability.viewTime)}
          </td>
          <td class="px-4 py-4 text-right"> {numberWithCommas(capability.contentViews)} </td>
          <td class="px-4 py-4 whitespace-nowrap">
            <DataTableActions row={capability} />
          </td>
        </tr>
      {/each}
    </tbody>
  </table>
</div>
