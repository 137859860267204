<script lang="ts">
  // External imports
  import { createEventDispatcher } from 'svelte';
  import { writable } from 'svelte/store';
  import { flexRender } from '@tanstack/svelte-table';
  // Other components
  import * as Tooltip from '$lib/components/ui/tooltip';
  import { Button } from '$lib/components/ui/button';
  import { Input } from '$lib/components/ui/input';
  import Icon from '$shared/icons/Icon.svelte';

  export let table: any = writable();
  export let tooltips: any = {};
  export let canFilter: Boolean = false;
  export let filterLabel: string = '';
  export let selectedRowStore = null;
  export let tableName: string = '';
  export let sortServerside = false;
  export let sortServersideSettings = {
    id: null,
    order: null
  };

  const dispatch = createEventDispatcher();

  const handleKeyUp = (e: any) => {
    $table.setGlobalFilter(String(e?.target?.value))
    dispatch('filter-table', String(e?.target?.value));
  }
</script>

{#if canFilter || $$slots.buttons}
  <h2 class="text-l font-semibold">{tableName}</h2>
  <div class="filter-and-buttons">
    <div class="filter">
      <Input
        placeholder={filterLabel}
        class="border w-full p-1 w-full"
        type="text"
        on:keyup={handleKeyUp}
      />
    </div>
    <div class="buttons space-x-2 space-y-2">
      <slot name="buttons"></slot>
    </div>
  </div>
{/if}

<div class="table-wrapper">
  <table>
    <thead>
      {#each $table.getHeaderGroups() as headerGroup}
        <tr>
          {#each headerGroup.headers as header}
            <th>
              {#if !header.isPlaceholder}
                {#if header.column.getCanSort()}
                  <button on:click={ sortServerside ? dispatch('sort-table', header.column) :  header.column.getToggleSortingHandler()} class="header-button">
                    {#if tooltips}
                      <Tooltip.Root>
                        <Tooltip.Trigger>
                          <svelte:component
                            this={flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          />
                        </Tooltip.Trigger>
                        <Tooltip.Content>
                          <p>{tooltips[header.id]}</p>
                        </Tooltip.Content>
                      </Tooltip.Root>
                    {:else}
                      <svelte:component
                        this={flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      />
                    {/if}
                    {#if header.column.getIsSorted().toString() === 'asc' ||
                      (sortServersideSettings.id === header.column.id && sortServersideSettings.order === 'asc')
                    }
                      <Icon icon="ArrowUp" size="small" iconClass="ml-1" />
                    {:else if header.column.getIsSorted().toString() === 'desc' ||
                      (sortServersideSettings.id === header.column.id && sortServersideSettings.order === 'desc')}
                      <Icon icon="ArrowDown" size="small" iconClass="ml-1" />
                    {:else}
                      <Icon icon="ChevronsUpDown" size="small" iconClass="ml-1" />
                    {/if}
                  </button>
                {:else}
                  <svelte:component
                    this={flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  />
                {/if}
              {/if}
            </th>
          {/each}
        </tr>
      {/each}
    </thead>
    <tbody>
      {#each $table.getRowModel().rows as row}
        <tr>
          {#each row.getVisibleCells() as cell}
            <td>
              <svelte:component
                this={flexRender(cell.column.columnDef.cell, cell.getContext())}
              />
            </td>
          {/each}
        </tr>
      {/each}
    </tbody>
  </table>
</div>

<div class="selection-and-pagination">
  <div class="selection">
    {#if selectedRowStore}
      {@const selectedRows = Object.keys(selectedRowStore).length}
      {@const pageIndex = $table.getState().pagination.pageIndex}
      {@const pageSize = $table.getState().pagination.pageSize}
      {@const rowsVisible = $table.getRowModel().rows.length}
      {@const firstVisible = pageIndex * pageSize + 1}
      {@const lastVisible = pageIndex * pageSize + rowsVisible}
      {selectedRows} of {$table.getRowCount()} selected, showing {firstVisible} to {lastVisible}
    {/if}

  </div>
  <div class="pagination space-x-2">
    {#if $table.getCanPreviousPage() || $table.getCanNextPage()}
      <span class="m-r-2">Page {$table.getState().pagination.pageIndex + 1} of {$table.getPageCount()}</span>
      {#if $table.getCanPreviousPage()}
        <Button 
          on:click={$table.previousPage()}
          size="sm"
          variant="outline"
          class="select-none"
        >
          Prev
        </Button>
      {/if}
      {#if $table.getCanNextPage()} 
        <Button 
          on:click={$table.nextPage()}
          size="sm"
          variant="outline"
          class="select-none"
        >
          Next
        </Button> 
      {/if}
    {/if}
  </div>
</div>

<style>
  .table-wrapper {
    background: theme("colors.white");
    border: 1px solid theme("colors.neutral.200");
    border-radius: theme("borderRadius.md");
    width: 100%;
    overflow: auto;
  }
  table {
    width: 100%;
    caption-side: bottom;
    font-size: theme("fontSize.sm");
  }
  thead {
    position: sticky;
    top: 0;
    background: theme("colors.white");
    z-index: 50;
    border-bottom: 1px solid theme("colors.neutral.200");
  }
  th {
    text-align: left;
    padding-right: 1rem;
    padding-left: .5rem;
    font-weight: 400;
    color: theme("colors.neutral.500");
  }
  tr {
    height: 40px;
    border-bottom: 1px solid theme("colors.neutral.200");
    transition: background-color 50ms cubic-bezier(0.4, 0, 0.2, 1);
    &:hover {
      background-color: hsl(var(--muted) / 50);
    }
    &:last-of-type {
      border: none;
    }
  }
  td {
    padding: theme("spacing.2");
    vertical-align: middle;

  }

  h2 {
    margin-bottom: 1rem;
  }

  .header-button {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  .filter-and-buttons {
    display: flex;
    flex-direction: row;
    gap: theme("spacing.2");
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: theme("spacing.4");
    flex-wrap: wrap;
    @media (min-width: theme("screens.lg")) {
      flex-wrap: nowrap;
    }
  }

  .filter {
    display: flex;
    flex-basis: 100%;
    max-width: 24rem;
    @media (max-width: theme("screens.lg")) {
      align-self: flex-start;
      max-width: 100%;
    }
  }

  .buttons {
    display: flex;
    @media (max-width: theme("screens.lg")) {
      justify-content: flex-end;
      flex-grow: 1;
      flex-wrap: wrap;
    }
  }

  .selection-and-pagination {
    display: flex;
    justify-content: space-between;
    margin-top: theme("spacing.4");
    font-size: theme("fontSize.sm");
    @media (max-width: theme("screens.sm")) {
      flex-wrap: wrap;
    }
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
</style>