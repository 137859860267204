<svelte:options customElement={{ tag: "bt-modal-container", shadow: "none", }} />

<script lang="ts">
  import { modalStore } from '$shared/modal/modalStore';

  import AddToCurations from "$comp/curations/AddToCurations.svelte";
  import MockModal from "$shared/modal/mock/MockModal.svelte";

  export let addToCurationTextTitle = 'Add to Curation';
  export let addToCurationTextClose = 'Close';
  export let addToCurationTextEnter = 'Enter';
  export let addToCurationTextPlaceholder = 'New Curation';

  $: modalName = $modalStore.modalName
</script>

<div>
  {#if modalName === 'addToCuration'}
    <AddToCurations 
      {addToCurationTextTitle}
      {addToCurationTextClose}
      {addToCurationTextEnter}
      {addToCurationTextPlaceholder}
      {...$modalStore.modalData} 
    />
  {/if}
  {#if modalName === 'mockModal'}
    <MockModal {...$modalStore.modalData} />
  {/if}
</div>