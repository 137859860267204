<svelte:options customElement={{ tag: "bt-toggle-button", shadow: "none", }} />

<script lang="ts">
  import Icon from '$shared/icons/Icon.svelte'

  export let putUrl: string = '';
  export let getUrl: string = '';
  export let icon: string = '';
  export let initialValue: Boolean;
  export let trueText: string = 'TRUE';
  export let falseText: string = 'FALSE';
  export let booleanProperty: string = '';

  export let storybookMode = false;
</script>

  <div data-controller="togglebutton" 
    data-togglebutton-put-url-value={putUrl}
    data-togglebutton-get-url-value={getUrl}
    data-togglebutton-initial-value={initialValue}
    data-togglebutton-true-text-value={trueText}
    data-togglebutton-false-text-value={falseText}
    data-togglebutton-boolean-property-value={booleanProperty}
    >
    <button type="submit" 
      data-action="click->togglebutton#submit" 
      data-togglebutton-target="button"
      class="p-4 bg-neutral-900 text-white hover:bg-transparent hover:text-neutral-900 border group">
      <div>
        <span data-togglebutton-target="text" class="font-semibold">{#if storybookMode}Button text{/if}</span>
      </div>
      {#if icon}
      <span class="ml-4">
          <Icon icon={icon} size="medium"></Icon>
      </span>
      {/if}
    </button>
  </div>

<style>
  button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  button:has(div>span:empty) {
    display: none;
  }
  div {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  @media print {
    button {
      display: none !important;
    }
  }
</style>
