<svelte:options customElement={{ tag: "plus-player", shadow: "none", }} />

<script>
  import "vidstack/styles/defaults.css";

  import Hls from "hls.js";
  import mux from "mux-embed";
  import { onMount } from "svelte";
  import { defineCustomElements } from "vidstack/elements";

  import "vidstack/icons";

  const muxEnvironments = {
    development: "e74bakvfgbrk5mjnc4vh50n7t",
    production: "6m6usp1v0e7c06jh6v1obogtg",
  };
  const muxPlayerInitTime = mux.utils.now();
  const domains = {
    development: [
      "https://v1.dev.bigthink.com",
      "https://v2.dev.bigthink.com",
    ],
    production: ["https://v1.bigthink.com", "https://v2.bigthink.com"],
  };

  let player;
  let playerTries = 0;

  export let debug = "false";
  export let env = "production";
  export let poster = null;
  export let sliderVideo = null;
  export let source = null;
  export let thumbnails = null;
  export let video = null;

  async function fetchData(url) {
    const response = await fetch(url);
    const jsonData = await response.json();
    return jsonData;
  }

  async function checkUrl(url) {
    try {
      const response = await fetch(url, { method: "HEAD" });
      return response.ok ? url : null;
    } catch {
      return null;
    }
  }

  async function findWorkingUrl(staticPath) {
    const host = domains;
    const urlChecks = host[env].map((domain) => checkUrl(domain + staticPath));
    try {
      return await Promise.race(
        urlChecks.map((promise) =>
          promise.then((url) => url && Promise.reject(url))
        )
      );
    } catch (url) {
      return url;
    }
  }

  async function getPoster(video) {
    if (!poster) {
      poster = await findWorkingUrl(`/${video}.poster.jpg`);
    }
  }

  async function getSliderVideo(video) {
    if (!sliderVideo) {
      sliderVideo = await findWorkingUrl(`/${video}.thumbs.mp4`);
    }
  }

  async function getSource(video) {
    if (!source) {
      source = await findWorkingUrl(`/${video}.m3u8`);
    }
  }

  async function getTextTracks(video, outlet) {
    const staticPath = `/${video}.subtitles.json`;
    const url = await findWorkingUrl(staticPath);
    if (url) {
      const domain = url.split(staticPath)[0];
      const subtitles = await fetchData(url);
      for (const subtitle of subtitles) {
        const track = document.createElement("track");
        track.id = subtitle.language;
        track.kind = subtitle.kind;
        track.label = subtitle.label;
        track.srclang = subtitle.language;
        track.src = `${domain}/${subtitle.src}`;
        track.setAttribute("data-type", subtitle.track_type);
        outlet.appendChild(track);
      }
    }
  }

  async function waitForPlayer() {
    playerTries++;
    if (playerTries >= 300) {
      console.error("Player never initialized");
      return;
    }

    if (!player) {
      await new Promise((resolve) => setTimeout(resolve, 100));
      return waitForPlayer();
    }
    return;
  }

  onMount(async () => {
    await defineCustomElements();
    await waitForPlayer();

    const outlet = player.querySelector("media-outlet");
    const debugMode = debug === "true";

    if (video) {
      await Promise.all([
        getPoster(video),
        getSliderVideo(video),
        getSource(video),
        getTextTracks(video, outlet),
      ]);
    }

    player.addEventListener("can-play", (event) => {
      if (debugMode) console.log(event);
      const newEvent = new CustomEvent("player-ready", {
        detail: event.detail,
        bubbles: true,
        cancelable: true,
        composed: true, // makes the event jump shadow DOM boundary
      });
      player.dispatchEvent(newEvent);
    });

    player.addEventListener("hls-error", (event) => {
      if (debugMode) console.log(event);
      const data = event.detail;
      const hls = event.target.provider.instance;
      if (data.fatal) {
        switch (data.type) {
          case Hls.ErrorTypes.MEDIA_ERROR:
            console.log("fatal media error encountered, trying to recover");
            hls.recoverMediaError();
            break;
          case Hls.ErrorTypes.NETWORK_ERROR:
            console.error("fatal network error encountered", data);
            // All retries and media options have been exhausted.
            // Immediately trying to restart loading could cause loop loading.
            // Consider modifying loading policies to best fit your asset and network
            // conditions (manifestLoadPolicy, playlistLoadPolicy, fragLoadPolicy).
            break;
          default:
            // cannot recover
            console.log("cannot recover");
            hls.destroy();
            break;
        }
      }
    });

    player.addEventListener("provider-change", (event) => {
      if (debugMode) console.log(event);
      const provider = event.detail;
      if (provider?.type === "hls") {
        provider.library = Hls;
        provider.config = {
          debug: debugMode,
          manifestLoadPolicy: {
            default: {
              maxTimeToFirstByteMs: 3000,
              maxLoadTimeMs: 10000,
              timeoutRetry: {
                maxNumRetry: 0,
                retryDelayMs: 0,
                maxRetryDelayMs: 0,
              },
              errorRetry: {
                maxNumRetry: 0,
                retryDelayMs: 1000,
                maxRetryDelayMs: 8000,
              },
            },
          },
          playlistLoadPolicy: {
            default: {
              maxTimeToFirstByteMs: 3000,
              maxLoadTimeMs: 7000,
              timeoutRetry: {
                maxNumRetry: 0,
                retryDelayMs: 0,
                maxRetryDelayMs: 0,
              },
              errorRetry: {
                maxNumRetry: 0,
                retryDelayMs: 1000,
                maxRetryDelayMs: 8000,
              },
            },
          },
          fragLoadPolicy: {
            default: {
              maxTimeToFirstByteMs: 3000,
              maxLoadTimeMs: 7000,
              timeoutRetry: {
                maxNumRetry: 0,
                retryDelayMs: 0,
                maxRetryDelayMs: 0,
              },
              errorRetry: {
                maxNumRetry: 0,
                retryDelayMs: 1000,
                maxRetryDelayMs: 8000,
              },
            },
          },
        };
      }
    });

    player.addEventListener("provider-setup", (event) => {
      if (debugMode) console.log(event);
      const provider = event.detail;
      if (provider?.type === "hls") {
        mux.monitor(provider.video, {
          debug: debugMode,
          hlsjs: provider.instance,
          Hls: Hls,
          data: {
            env_key: muxEnvironments[env],
            // Player metadata
            player_name: "FTMMM Player", // any arbitrary string you want to use to identify this player
            player_init_time: muxPlayerInitTime, // ex: 1451606400000
            // Video metadata
            video_id: video,
            video_title: video,
          },
        });
      }
    });
  });
</script>

<media-player
  class="overflow-hidden shadow-lg aspect-video w-full"
  src={source}
  {poster}
  {thumbnails}
  aspect-ratio="16/9"
  volume="0.5"
  bind:this={player}
>
  <media-outlet>
    <media-poster />
    <slot />
    <media-gesture
      class="top-0 left-0 h-full w-full"
      event="pointerup"
      action="toggle:paused"
    />
    <media-gesture
      class="top-0 left-0 h-full w-full"
      event="dblpointerup"
      action="toggle:fullscreen"
    />
    <media-gesture
      class="top-0 left-0 z-10 h-full w-1/5"
      event="dblpointerup"
      action="seek:-10"
    />
    <media-gesture
      class="top-0 right-0 z-10 h-full w-1/5"
      event="dblpointerup"
      action="seek:10"
    />
  </media-outlet>
  <div
    class="z-0 bg-black/30 transition-opacity opacity-0 not-user-idle:opacity-100 pointer-events-none bg-blur absolute inset-0 w-full h-full"
  />
  <media-captions
    class="not-user-idle:bottom-[80px] transition-[bottom] duration-300 z-10"
  />
  <media-buffering-indicator />
  <div
    class="can-control:opacity-100 pointer-events-none absolute inset-0 z-10 flex h-full flex-col justify-between text-white opacity-0 transition-opacity duration-200 ease-linear"
  >
    <div
      class="absolute inset-0 z-50 pointer-events-none user-idle:pointer-events-auto user-idle:cursor-none"
    />

    <div
      class="flex items-center pointer-events-auto w-fill px-2 z-50 mt-2"
    >
      <div class="flex-1" />
      <media-menu position="bottom">
        <media-menu-button>
          <media-icon type="settings" data-rotate />
          <media-tooltip position="bottom right">Settings</media-tooltip>
        </media-menu-button>
        <media-menu-items>
          <media-menu>
            <media-playback-rate-menu-button />
            <media-playback-rate-menu-items />
          </media-menu>
          <media-menu>
            <media-captions-menu-button />
            <media-captions-menu-items />
          </media-menu>
        </media-menu-items>
      </media-menu>
    </div>

    <div class="flex-1" />
    <div
      class="flex items-center pointer-events-auto w-full px-4 -mb-2 z-10"
    >
      <media-time
        class="text-sm tracking-wide mr-1"
        type="current"
      />
      <media-time-slider>
        {#if thumbnails}
          <div slot="preview">
            <div>
              <media-slider-thumbnail class="rounded-sm" />
              <div
                class="absolute bottom-8 w-full text-center"
                part="chapter-title"
              />
            </div>
            <media-slider-value
              class="rounded-sm mt-2"
              type="pointer"
              format="time"
            />
          </div>
        {:else}
          <media-slider-video src={sliderVideo} slot="preview" />
        {/if}
      </media-time-slider>
      <media-time
        class="text-sm tracking-wide ml-1"
        type="current"
        remainder
      />
    </div>
    <div
      class="flex items-center pointer-events-auto w-full mb-1 z-20 px-0.5"
    >
      <media-play-button data-amp-default-track default-appearance>
        <media-tooltip position="top left">
          <span slot="play">Play (k)</span>
          <span slot="pause">Pause (k)</span>
        </media-tooltip>
      </media-play-button>
      <media-mute-button class="-ml-1" default-appearance>
        <media-tooltip>
          <span slot="mute">Mute (m)</span>
          <span slot="unmute">Unmute (m)</span>
        </media-tooltip>
      </media-mute-button>
      <media-volume-slider class="max-w-[64px] -ml-1.5">
        <media-slider-value
          class="rounded-sm"
          type="pointer"
          format="percent"
          slot="preview"
        />
      </media-volume-slider>
      <div class="flex-1 text-center" />
      <media-caption-button default-appearance>
        <media-tooltip>
          <span slot="on">Closed-Captions On (c)</span>
          <span slot="off">Closed-Captions Off (c)</span>
        </media-tooltip>
      </media-caption-button>
      <media-pip-button default-appearance>
        <media-tooltip>
          <span slot="enter">Enter PIP (i)</span>
          <span slot="exit">Exit PIP (i)</span>
        </media-tooltip>
      </media-pip-button>
      <media-fullscreen-button class="right-2" default-appearance>
        <media-tooltip position="top right">
          <span slot="enter">Enter Fullscreen (f)</span>
          <span slot="exit">Exit Fullscreen (f)</span>
        </media-tooltip>
      </media-fullscreen-button>
    </div>
  </div>
</media-player>